<template>
  <div class="get-centre">
    <div class="nav">
      <van-icon
        name="arrow-left"
        color="#707070"
        class="nav-left"
        @click="closePopup"
      />
      <span>领券中心</span>
    </div>
    <div class="centre-list">
      <CentreList></CentreList>
    </div>
    <div class="bottom-btn" @click="openGetCentre">
      <div class="btn-box">
        <span class="btn-text">我的优惠券</span>
        <van-icon name="arrow" color="#ed301d" size="18" />
      </div>
    </div>
  </div>
</template>

<script>
import CentreList from "../components/centre-list/centre-list";
export default {
  name: "get-centre",
  components: {
    CentreList,
  },
  data() {
    return {};
  },
  methods: {
    openGetCentre() {
      this.$router.go(-1);
    },
    closePopup() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.get-centre {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: rgb(249, 249, 249);
}
.nav {
  position: relative;
  height: 57px;
  text-align: center;
  line-height: 57px;
  font-size: 21px;
  font-weight: 500;
  border-bottom: 1px solid #ededed;
  background-color: #fff;

  .nav-left {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.centre-list {
  flex: 1;
  overflow: auto;
}
.bottom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 63px;
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  .btn-box {
    display: flex;
    align-items: center;
  }
  .btn-text {
    font-size: 21px;
    font-weight: 500;
    color: #ed301d;
  }
}
</style>