<template>
  <div class="discounts-box">
    <ul>
      <li class="discounts-list" v-for="(item, index) in list" :key="item.id">
        <!-- 已领取--img -->
        <img
          src="@/assets/discounts/expire.png"
          class="expire"
          v-if="item.is_receive === 1 && item.status !== 2"
        />
        <!-- 今日到期--img -->
        <img
          src="@/assets/discounts/duetoday@2x.png"
          class="expire-icon"
          v-if="item.is_day === 1 && item.status !== 4"
        />
        <!-- 未生效--img -->
        <img
          src="@/assets/discounts/pulldown_icon.png"
          class="no-take"
          v-if="item.is_receive === 1 && item.status === 2"
        />
        <div class="discounts-number" v-if="item.num > 0">{{ item.num }}张</div>
        <div class="list-left-box">
          <div class="list-left">
            <div class="discounts-price">
              <Price
                :price="item.money"
                integerStyle="26"
                decimalStyle="13"
              ></Price>
            </div>
            <div class="overflow-subtract">
              {{
                item.full_type === 1 ? `满${item.full_money}元可减` : "无门槛"
              }}
            </div>
          </div>
        </div>
        <div class="list-right">
          <div class="describe-text">{{ restrictLength(item.name) }}</div>
          <div class="right-bottom">
            <div class="time">{{ item.time_str }}</div>
            <div
              class="get-btn"
              v-if="item.is_receive === 0"
              @click="getDiscounts(item, index)"
            >
              立即领取
            </div>
            <div
              class="fast-use"
              v-else-if="item.status !== 2"
              @click="goToUrl(item)"
            >
              立即使用
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="blank-box" v-if="showBlank">
      <div class="blank">
        <img src="@/assets/blank/icon@2x.png" class="blank-img" />
        <span class="blank-text">暂无优惠券</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getCanReceiveList, getDiscounts } from "@/api/discounts.js";
import { Toast } from "vant";
import { intercept } from "@/common/js/utils.js";
export default {
  data() {
    return {
      showBlank: false,
      list: [],
      discount: {
        type: 1,
        coupon_id: 0,
        grant_id: 0,
      },
    };
  },
  mounted() {
    this.getCanReceiveList();
  },
  watch: {},
  methods: {
    async getDiscounts(item, index) {
      this.discount.coupon_id = item.coupon_id;
      this.discount.grant_id = item.grant_id;
      const res = await getDiscounts(this.discount);
      if (res.code === 1) {
        this.list[index].is_receive = 1;
        Toast.success(res.msg);
      }
    },
    goToUrl(item) {
      this.$router.push({
        name: "discounts",
        query: {
          couponId: item.coupon_id,
          grantId: item.grant_id,
        },
      });
    },
    async getCanReceiveList() {
      const res = await getCanReceiveList();
      if (res.code === 1) {
        if (res.data.length <= 0 && this.list.length <= 0) {
          this.showBlank = true;
        } else {
          this.showBlank = false;
          this.list = [...this.list, ...res.data];
        }
      }
      intercept(this.list);
    },
    restrictLength(str) {
      let s = "";
      if (str.length >= 13) {
        s = str.substring(0, 18);
        s = s + "...";
      } else {
        s = str;
      }
      return s;
    },
    firstFliter(str) {
      return str.split(" ")[0];
    },
    lastFliter(str) {
      return str.split(" ")[1];
    },
  },
};
</script>

<style lang="less" scoped>
.blank-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .blank {
    display: flex;
    flex-direction: column;
    align-items: center;
    .blank-img {
      width: 221px;
      height: 144px;
    }
    .blank-text {
      text-align: center;
      margin-top: 13px;
      color: #aaaaaa;
      font-size: 17px;
    }
  }
}
ul {
  margin-top: 10px;
  padding: 0 13px;
  .discounts-list {
    position: relative;
    display: flex;
    background: url("../../../../assets/discounts/bg1@2x.png") no-repeat center /
      100% 100%;
    margin-bottom: 10px;
    .expire {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      width: 68px;
      height: auto;
    }
    .no-take {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 68px;
      height: auto;
    }
    .discounts-number {
      position: absolute;
      width: 35px;
      height: 17px;
      text-align: center;
      line-height: 17px;
      color: #fff;
      background: linear-gradient(90deg, #ffd871 0%, #f69f1c 100%);
      border-radius: 2px;
    }
  }
}
.list-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 118px;
  height: 106px;
  color: #ed301d;
  .discounts-price {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .overflow-subtract {
    font-size: 13px;
    font-weight: 500;
  }
}
.list-right {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  height: 106px;
  padding: 12px;
  box-sizing: border-box;
  .describe-text {
    font-size: 17px;
  }
  .get-btn {
    width: 83px;
    height: 34px;
    background: rgb(245, 76, 64);
    border-radius: 34px;
    text-align: center;
    line-height: 34px;
    font-size: 16px;
    color: #fff;
  }
  .expire {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 68px;
    height: auto;
  }
}
.right-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .fast-use {
    width: 83px;
    height: 34px;
    border: 1px solid #f54c40;
    border-radius: 34px;
    font-size: 16px;
    text-align: center;
    line-height: 34px;
    color: #ed301d;
  }
}
.time {
  color: #999999;
  font-size: 12px;
}
.deadline {
  text-align: right;
  color: #999999;
  font-size: 13px;
}
</style>